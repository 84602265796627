<template>
    <v-card class="mt-3">
        <v-layout>
            <v-flex xs3 class="t-center">
                <v-avatar center size="124" tile>
                    <v-img :src="resizeImagePath()" v-if="imagePath" contain></v-img>
                    <i class="fas fa-camera-alt fa-2x" v-else></i>
                </v-avatar>
            </v-flex>
            <v-flex xs9 pt-1 class="bg-gray-light pl-3">
                <v-card-title class="text--primary pb-1 pl-0 pt-4">
                    <div class="font-weight-bold">{{ title || 'Título do projeto' }}</div>
                </v-card-title>
                <v-card-subtitle>
                    {{ description || 'Descrição do projeto'}}
                </v-card-subtitle>
                <v-card-text class="mt-2 text-dark">
                    <small>
                        {{ urlPath() }}
                    </small>
                </v-card-text>
            </v-flex>
        </v-layout>
    </v-card>
</template>

<script type="plain/text">
    export default {
        name: "PreviewShare",
        props: ['title', 'description', 'imagePath','subdomain', ''],
        methods: {
            urlPath(){
                return this.subdomain ? this.subdomain + '.abraceumacausa.com.br' : 'abraceumacausa.com.br'
            },
            resizeImagePath(){
                return this.imagePath.replace('image/upload','image/upload/w_150,h_150,c_pad,b_auto:predominant/');
            },
        }
    }
</script>