const IncentiveLaws = {
    EstatutoDeCriancaEAdolescente: 1,
    EstatutoDoIdoso: 2,
    LeiFederalDoEsporte: 3,
    LeiIncentivoCulturaRouanet: 4
};

const IncentiveLawsText = [
    { id: IncentiveLaws.EstatutoDeCriancaEAdolescente, name: "Estatuto de Criança e Adolescente" },
    { id: IncentiveLaws.EstatutoDoIdoso, name: "Estatuto do Idoso" },
    { id: IncentiveLaws.LeiFederalDoEsporte, name: "Lei Federal do Esporte" },
    { id: IncentiveLaws.LeiIncentivoCulturaRouanet, name: "Lei de Incentivo à Cultura - Rouanet" }
]

export { IncentiveLaws, IncentiveLawsText };