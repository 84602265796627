<template>
  <div>
    <v-layout>
      <div class="boxapp">
        <!-- boxapp head -->
        <div class="boxapp-head">
          <a href="#" @click="$router.go(-1)">
            <faIcon type="fal" name="long-arrow-left" size="24" colorFont="#cfd2dd" />
          </a>
          <h1>{{ getProjectTypeName(project.projectTypeId) }}</h1>
        </div>

        <!-- boxapp body -->
        <div class="box-main">
          <Steps :page-options="pageOptions" :project="project" />

          <div class="section">
            <!-- section head -->
            <div class="head">
              <h5>PASSO 1</h5>
              <h4>Descrição</h4>
            </div>
            <!-- section body -->
            <div class="wrapper">
              <v-layout row wrap mw-half>
                <v-flex xs12>
                  <InputText
                    @focus="updateHelpBox"
                    :helpBoxText="helpBoxTextEnum.ProjectStep1.Description.FullName"
                    ref="fullNameInput"
                    v-model="project.fullName"
                    type="text"
                    place-holder="Escreva o nome aprovado para este projeto"
                    textLabel="Nome Completo do Projeto"
                    helpLabel="Ex: Abrace uma Causa"
                    invalidInputMessage="Preencha o nome completo do projeto"
                    @validationStatusChanged="validateStep"
                    :focusOnCreate="true"
                    labelCharLimit="255"
                  />
                </v-flex>
                <v-flex xs12>
                  <InputText
                    @focus="updateHelpBox"
                    :helpBoxText="helpBoxTextEnum.ProjectStep1.Description.ShortName"
                    ref="shortNameInput"
                    v-model="project.shortName"
                    type="text"
                    place-holder="Dê um nome curto para este projeto"
                    textLabel="Nome Resumido do Projeto"
                    helpLabel="Ex: AUC"
                    invalidInputMessage="Preencha o nome resumido do projeto"
                    @validationStatusChanged="validateStep"
                    labelCharLimit="54"
                  />
                </v-flex>
                <v-flex xs12>
                  <InputSelect
                    @focus="updateHelpBox"
                    ref="causeInput"
                    :helpBoxText="helpBoxTextEnum.ProjectStep1.Description.Couse"
                    v-model="project.causeId"
                    :items="causeList"
                    textLabel="Causa"
                    helpLabel="Ex: Cultura"
                    valueAttribute="id"
                    textAttribute="name"
                    invalidInputMessage="Selecione a causa do projeto"
                    @validationStatusChanged="validateStep"
                  ></InputSelect>
                </v-flex>
                <v-flex xs12 v-if="project.projectTypeId == projectTypes.ProjetoIncentivado">
                  <InputSelect
                    @focus="updateHelpBox"
                    ref="incentiveLawInput"
                    :helpBoxText="helpBoxTextEnum.ProjectStep1.Description.IncentiveLaw"
                    v-model="project.incentiveLawId"
                    :items="incentiveLawList"
                    textLabel="Lei de Incentivo"
                    helpLabel="Ex: Estatuto do idoso"
                    valueAttribute="id"
                    textAttribute="name"
                    invalidInputMessage="Selecione a lei de incentivo do projeto"
                    @validationStatusChanged="validateStep"
                  ></InputSelect>
                </v-flex>
                <v-flex
                  xs12
                  v-show="project.incentiveLawId == incentiveLaws.EstatutoDeCriancaEAdolescente"
                >
                  <InputSelect
                    @focus="updateHelpBox"
                    ref="childrenFundInput"
                    :helpBoxText="helpBoxTextEnum.ProjectStep1.Description.Children"
                    v-model="project.childrenFundId"
                    :items="childrenFundList"
                    textLabel="Fundo de Criança e Adolescente"
                    helpLabel="Ex: "
                    valueAttribute="id"
                    textAttribute="name"
                    :validation="validateChildrenFundSelect"
                    invalidInputMessage="Selecione o fundo de criança e adolescente do projeto"
                    @validationStatusChanged="validateStep"
                  ></InputSelect>
                </v-flex>
                <v-flex xs12 v-show="project.incentiveLawId == incentiveLaws.EstatutoDoIdoso">
                  <InputSelect
                    @focus="updateHelpBox"
                    ref="elderlyFundInput"
                    :helpBoxText="helpBoxTextEnum.ProjectStep1.Description.Old"
                    v-model="project.elderlyFundId"
                    :items="elderlyFundList"
                    textLabel="Fundo de Idoso"
                    helpLabel="Ex: "
                    valueAttribute="id"
                    textAttribute="name"
                    :validation="validateElderlyFundSelect"
                    invalidInputMessage="Selecione o fundo de idoso do projeto"
                    @validationStatusChanged="validateStep"
                  ></InputSelect>
                </v-flex>

                <template v-if="project.incentiveLawId == incentiveLaws.LeiIncentivoCulturaRouanet">
                  <v-flex xs12>
                    <InputText
                      ref="pronacNumberInput"
                      v-model="project.pronacNumber"
                      type="text"
                      place-holder="Nº do PRONAC"
                      textLabel="Nº do PRONAC"
                      :required="true"
                      @validationStatusChanged="validateStep"
                      :validation="validatePronacNumberInput"
                      invalidInputMessage="Selecione o número do PRONAC"
                      mask="########"
                    />
                  </v-flex>

                  <v-flex xs12>
                    <InputSelect
                      @focus="updateHelpBox"
                      ref="projectAreaInput"
                      v-model="project.projectAreaId"
                      :helpBoxText="helpBoxTextEnum.ProjectStep1.Description.Old"
                      :items="projectAreaList"
                      textLabel="Área"
                      helpLabel="Ex: Artes Cênicas"
                      valueAttribute="id"
                      textAttribute="name"
                      invalidInputMessage="Selecione a área"
                      @validationStatusChanged="validateStep"
                    ></InputSelect>
                  </v-flex>

                  <v-flex xs12 v-if="project.projectAreaId">
                    <InputSelect
                      @focus="updateHelpBox"
                      ref="projectSequelAreaInput"
                      :helpBoxText="helpBoxTextEnum.ProjectStep1.Description.Old"
                      v-model="project.projectSequelAreaId"
                      :items="projectSequelAreaList"
                      textLabel="Segmento"
                      helpLabel="Ex: Circo"
                      valueAttribute="id"
                      textAttribute="name"
                      :validation="validateProjectSequelAreaSelect"
                      invalidInputMessage="Selecione o segmento"
                      @validationStatusChanged="validateStep"
                    ></InputSelect>
                  </v-flex>
                </template>

                <v-flex xs12 v-if="project.incentiveLawId == incentiveLaws.LeiFederalDoEsporte">
                  <InputText
                    @focus="updateHelpBox"
                    ref="processNumberInput"
                    :helpBoxText="helpBoxTextEnum.ProjectStep1.Description.ProcessNumber"
                    v-model="project.processNumber"
                    type="text"
                    place-holder="Número do Processo"
                    textLabel="Número do Processo"
                    helpLabel="Ex: "
                    :validation="validateProcessNumber"
                    invalidInputMessage="Preencha o número do processo do projeto"
                    @validationStatusChanged="validateStep"
                    mask="#####.######/####-##"
                  />
                </v-flex>
                <v-flex xs12 v-if="project.incentiveLawId == incentiveLaws.LeiFederalDoEsporte || project.incentiveLawId == incentiveLaws.LeiIncentivoCulturaRouanet ">
                  <InputDate
                    @focus="updateHelpBox"
                    ref="publicationDateDOUInput"
                    :helpBoxText="helpBoxTextEnum.ProjectStep1.Description.PublicationDateDOU"
                    v-model="project.publicationDateDOU"
                    type="text"
                    place-holder="__/__/____"
                    textLabel="Data de publicação DOU"
                    invalidInputMessage="Data de publicação DOU"
                    :validation="validatePublicationDateDOU"                    
                    @validationStatusChanged="validateStep"
                  />
                </v-flex>
                <v-flex xs12 v-show="project.incentiveLawId == incentiveLaws.LeiFederalDoEsporte">
                  <InputSelect
                    @focus="updateHelpBox"
                    ref="sportingManifestationInput"
                    :helpBoxText="helpBoxTextEnum.ProjectStep1.Description.Manifestation"
                    v-model="project.sportingManifestationId"
                    :items="sportingManifestationList"
                    textLabel="Manifestação Desportiva"
                    helpLabel="Ex: Desporto Educacional"
                    valueAttribute="id"
                    textAttribute="name"
                    :validation="validateSportingManifestation"
                    invalidInputMessage="Selecione a manifestação desportiva do projeto"
                    @validationStatusChanged="validateStep"
                  ></InputSelect>
                </v-flex>
                <v-flex xs12>
                  <InputSelect
                    @focus="updateHelpBox"
                    ref="targetAudienceInput"
                    :helpBoxText="helpBoxTextEnum.ProjectStep1.Description.PublicTarget"
                    v-model="project.targetAudienceId"
                    :items="targetAudienceList"
                    textLabel="Público"
                    helpLabel="Ex: Idosos"
                    valueAttribute="id"
                    textAttribute="name"
                    :required="false"
                  ></InputSelect>
                </v-flex>
                <div v-if="project.projectTypeId == projectTypes.Ticket">
                  <v-flex xs12>
                    <InputText
                      v-model.number="project.maxItemQuantityPerCPF"
                      type="tel"
                      textLabel="Limite de compra por CPF"
                      helpLabel="Ex: 2"
                      place-holder="Número máximo de itens permitidos por compra"
                      mask="#########"
                    />
                  </v-flex>
                  <v-flex xs12>
                    <InputMoney
                      ref="totalValueInput"
                      v-model="project.totalValue"
                      textLabel="Valor total do projeto"                      
                      invalidInputMessage="Preencha o valor total do projeto"
                      :precision="2"
                      :required="false"
                    />
                  </v-flex><br>
                </div>
                <div>
                  <v-flex xs12>
                    <InputText
                      ref="receiptsNumberInput"
                      v-model="project.receiptNumber"
                      type="text"
                      textLabel="Numeração inicial de recibo"
                      mask="#####"
                    />
                  </v-flex>
                </div>
              </v-layout>
            </div>

            <BorderSeparation />

            <div class="head">
              <h4>Configurações para compartilhamento</h4>
            </div>

            <v-layout row wrap mw-total>
              <v-flex
                class="upload-box-profile-father"
                style="width: 200px !important; height: 160px"
                shrink
                pr-3
              >
                <div
                  class="upload-image bg-contain"
                  style="width:200px;min-height: 160px"
                  id="share"
                  ref="share"
                >
                  <label for="upload-campaign-share">
                    <i class="fas fa-camera-alt"></i>
                  </label>
                  <label
                    for="upload-campaign_share"
                    class="do-upload"
                    v-show="project.faviconFile.path == '' || project.faviconFile.path==null"
                  >
                    <i class="fal fa-cloud-upload"></i>Ícone
                  </label>
                  <input
                    type="file"
                    id="upload-campaign_share"
                    accept="image/*"
                    @change="faviconFileChanged"
                    ref="campaign_upload_share"
                  />
                </div>
              </v-flex>

              <v-flex md8>
                <v-layout row wrap>
                  <v-flex xs12>
                    <InputText
                      ref="metaTitleInput"
                      v-model="project.metaTitle"
                      type="text"
                      place-holder="Título"
                      textLabel="Título"
                      labelCharLimit="60"
                      :required="false"
                    />
                  </v-flex>
                  <v-flex xs12>
                    <InputTextArea
                      ref="metaDescriptionInput"
                      v-model="project.metaDescription"
                      rows="3"
                      place-holder="Descrição"
                      textLabel="Descrição"
                      labelAreaCharLimit="160"
                      :required="false"
                    />
                  </v-flex>
                </v-layout>

                <hr class="sm my-3" />
                <v-layout row wrap>
                  <v-flex
                    f-size-14
                    caption
                    text-gray-dark
                    mt-1
                    xs12
                  >O upload da imagem de compartilhamento e favicon deve ter até 500kb e ficará como exibido abaixo.</v-flex>
                  <v-flex caption text-gray-dark mt-1 xs12>Preview fav-icon: Google Chrome</v-flex>
                  <v-flex mt-2>
                    <div class="browser-bg light">
                      <span
                        class="fav-icon-preview"
                        :data-text="project.metaTitle"
                        id="favIconPreview1"
                        ref="favIconPreview1"
                      ></span>
                    </div>
                  </v-flex>
                  <v-flex mt-2>
                    <div class="browser-bg dark">
                      <span
                        class="fav-icon-preview"
                        :data-text="project.metaTitle"
                        id="favIconPreview2"
                        ref="favIconPreview2"
                      ></span>
                    </div>
                  </v-flex>
                </v-layout>

                <v-flex caption text-gray-dark mt-1 xs12>Preview Compartilhamento: Whatsapp</v-flex>
                <PreviewShare
                  :title="project.metaTitle"
                  :description="project.metaDescription"
                  :image-path="project.faviconFile.path || ''"
                  :subdomain="project.subdomain"
                ></PreviewShare>
              </v-flex>
            </v-layout>
          </div>

          <BorderSeparation v-if="project.projectTypeId != projectTypes.Ticket" />

          <div class="section" v-if="project.projectTypeId != projectTypes.Ticket">
            <!-- section head -->
            <div class="head">
              <h4>Objetivos de Desenvolvimento Sustentável (ODS)</h4>
              <p>Selecione abaixo pelo menos 1 dentre os objetivos que se enquadram no projeto</p>
            </div>
            <!-- section body -->
            <div class="checkbox-father mw-total">
              <InputCheckBoxList
                @focus="updateHelpBox"
                ref="odsListInput"
                :helpBoxText="helpBoxTextEnum.ProjectStep1.Ods"
                :list="odsList"
                v-model="project.odsList"
                labelAttribute="title"
                valueAttribute="id"
                :columns="2"
                :required="false"
              />
            </div>

            <div class="ref-onu">
              Referência:
              <a href="https://nacoesunidas.org/" target="_blank">
                onu
                <img valign="middle" src="../../../assets/images/onu-logo.png" border="0" alt />
              </a>
            </div>

            <BorderSeparation />

            <InputTextArea
              @focus="updateHelpBox"
              ref="objectiveInput"
              :helpBoxText="helpBoxTextEnum.ProjectStep1.Objective"
              v-model="project.objective"
              class="mt-3"
              labelAreaCharLimit="250"
              textLabel="Objetivo"
              :required="false"
            ></InputTextArea>
            <InputTextArea
              @focus="updateHelpBox"
              ref="socialProblemInput"
              :helpBoxText="helpBoxTextEnum.ProjectStep1.SocialProblem"
              v-model="project.socialProblem"
              class="mt-3"
              labelAreaCharLimit="500"
              textLabel="Problema Social"
              invalidInputMessage="Preencha o problema social do projeto"
              @validationStatusChanged="validateStep"
            ></InputTextArea>
            <InputTextArea
              @focus="updateHelpBox"
              ref="solutionInput"
              :helpBoxText="helpBoxTextEnum.ProjectStep1.Solution"
              v-model="project.solution"
              class="mt-3"
              labelAreaCharLimit="500"
              textLabel="Solução"
              invalidInputMessage="Preencha a solução do projeto"
              @validationStatusChanged="validateStep"
            ></InputTextArea>
            <InputTextArea
              @focus="updateHelpBox"
              ref="methodologyInput"
              :helpBoxText="helpBoxTextEnum.ProjectStep1.Methodology"
              v-model="project.methodology"
              class="mt-3"
              labelAreaCharLimit="500"
              textLabel="Metodologia"
              invalidInputMessage="Preencha a metodologia do projeto"
              @validationStatusChanged="validateStep"
            ></InputTextArea>
            <InputTextArea
              @focus="updateHelpBox"
              ref="targetAudienceDescriptionInput"
              :helpBoxText="helpBoxTextEnum.ProjectStep1.PublicTarget"
              v-model="project.targetAudienceDescription"
              class="mt-3"
              labelAreaCharLimit="150"
              textLabel="Publico Alvo do projeto"
              :required="false"
            ></InputTextArea>
            <label class="mt-3" for="minibioInput">Minibio</label>
            <vue-editor
              @focus="updateHelpBox"
              ref="minibioInput"
              v-model="projectLandingPage.minibio"
              textLabel="Minibio"
              :required="false"
            ></vue-editor>
            <InputText
              ref="videoInput"
              v-model="projectLandingPage.videoUrl"
              type="text"
              place-holder="Link do YouTube"
              textLabel="Vídeo"
              :required="false"
            />
          </div>
        </div>
      </div>

      <div class="box-help-spacer"></div>
      <HelpBox :text="helpBoxText" />
    </v-layout>
    <FooterStep v-model="pageOptions" :validationMethod="isValid" :project="project" />
  </div>
</template>

<script type="plain/text">
import FooterStep from "@/components/FooterStep.vue";
import Project from "@/scripts/models/project.model";
import ProjectService from "@/scripts/services/project.service.js";
import { IncentiveLaws } from "@/scripts/models/enums/incentiveLaws.enum.js";
import {
  ProjectTypes,
  GetProjectTypeName,
} from "@/scripts/models/enums/projectTypes.enum.js";
import ValidatableComponent from "@/components/base/ValidatableComponent.vue";
import { Routes } from "@/scripts/models/enums/routes.enum.js";
import HelpBox from "@/components/HelpBox.vue";
import { HelpBoxText } from "@/scripts/models/enums/helpBoxText.enum.js";
import { mapGetters, mapActions } from "vuex";

import FileService from "@/scripts/services/file.service.js";
import File from "@/scripts/models/file.model.js";
import ProjectLandingPageConfig from "@/scripts/models/projectLandingPageConfig.model.js";
import PreviewShare from "@/components/PreviewShare";
import { parse } from "date-fns";
import { VueEditor } from "vue2-editor";

export default {
  extends: ValidatableComponent,
  components: {
    PreviewShare,
    HelpBox,
    FooterStep,
    VueEditor
  },
  props: ["pageOptions"],
  data() {
    return {
      project: new Project(),
      projectService: new ProjectService(),
      fileService: new FileService(),
      projectLandingPage: new ProjectLandingPageConfig(),
      incentiveLawList: [],
      odsList: [],
      causeList: [],
      targetAudienceList: [],
      childrenFundList: [],
      elderlyFundList: [],
      sportingManifestationList: [],
      projectAreaList: [],
      projectAreaId: 0,
      incentiveLaws: IncentiveLaws,
      helpBoxText: HelpBoxText.ProjectStep1.FullName,
      helpBoxTextEnum: HelpBoxText,
      projectTypes: ProjectTypes,
      getProjectTypeName: GetProjectTypeName,
      projectValidationStatusIndex: 1,
      breadcrumbLevels: [
        { text: "Início" },
        { text: "Projetos" },
        { text: "Passo 1" },
      ],
    };
  },
  beforeRouteEnter: (to, from, next) => {
    if (to.query.projectId) {
      let projectService = new ProjectService();
      projectService.findById(to.query.projectId).then(function (data) {
        to.query.project = data;
        next();
      });
    } else {
      next();
    }
  },
  beforeRouteLeave(to, from, next) {
    let isValid = this.isValid(true);
    if (isValid) {
      if (this.project.id == 0 && to.query.projectId != 0) {
        this.project.id = to.query.projectId;
      }
      if (!this.$route.query.saved) {
        this.$route.query.saved = true;
        this.projectService.save(this.project).then(
          function (data) {
            this.projectLandingPage.projectId = data.id;
            this.projectService.saveLandingPageConfig(this.projectLandingPage);
            if (to.query.projectId == 0) {
              this.clearProject();
              next({ path: to.path, query: { projectId: data.id } });
            } else {
              next();
            }
          }.bind(this)
        );
      } else {
        next();
      }
    } else {
      if (
        to.name != Routes.app.ProjectStep2 &&
        to.name != Routes.app.ProjectStep3 &&
        to.name != Routes.app.ProjectStep4 &&
        to.name != Routes.app.ProjectStep50 &&
        to.name != Routes.app.ProjectStep51
      ) {
        next();
      } else {
        next(false);
      }
    }
  },
  created() {
    if (!this.$route.query.project) {
      this.project = this.newProject;
    } else {
      this.project = this.$route.query.project;

      this.project.pronacNumber =
        this.project.pronacNumber == null ? 0 : this.project.pronacNumber;

      this.loadFavicon();

      this.project.metaTitle =
        this.project.metaTitle == null ? "" : this.project.metaTitle;
      this.project.metaDescription =
        this.project.metaDescription == null
          ? ""
          : this.project.metaDescription;
    }
    this.$emit("changeLevels", this.breadcrumbLevels);
    this.showValidationFailMessageMethod = this.showFailMessage;
    this.onValidationSuccessMethod = this.setValidStep;
    this.onValidationFailMethod = this.setInvalidStep;
    this.projectService.getLandingPageConfigByProjectId(this.project.id).then(
      data => {
        this.projectLandingPage = data;
      }
    );
    this.projectService.listAllODS(this.listAllODSCallback);
    this.projectService.listAllIncentiveLaw(this.listAllIncentiveLawCallback);
    this.projectService.listAllCause(this.listAllCauseCallback);
    this.projectService.listAllTargetAudience(
      this.listAllTargetAudienceCallback
    );
    this.projectService.listAllChildrenFund(this.listAllChildrenFundCallback);
    this.projectService.listAllElderlyFund(this.listAllElderlyFundCallback);
    this.projectService.listAllSportingManifestation(
      this.listAllSportingManifestationCallback
    );
    this.projectService.listProjectAreas(this.projectAreasCallback);
    this.pageOptions.changeClass("footer");
    this.pageOptions.currentPage = 1;
  },
  mounted() {
    this.$refs.fullNameInput.focus();
  },
  computed: {
    ...mapGetters(["newProject"]),
    projectSequelAreaList() {
      if (this.project.projectAreaId > 0) {
        const projectAreaId = this.project.projectAreaId;
        let projectArea = this.projectAreaList.filter(
          (projectArea) => projectArea.id == projectAreaId
        )[0];
        return projectArea.projectSequelAreas;
      }

      return [];
    },
  },
  methods: {
    ...mapActions(["clearProject"]),
    setNewProject(value) {
      this.isNewProject = false;
    },
    updateHelpBox(text) {
      this.helpBoxText = text;
    },
    listAllODSCallback(data) {
      this.odsList = data;
    },
    listAllIncentiveLawCallback(data) {
      this.incentiveLawList = data;
    },
    listAllCauseCallback(data) {
      if (this.project.projectTypeId == ProjectTypes.Ticket) {
        data = data.filter(
          (cause) =>
            cause.name.toLowerCase().includes("carnaval") ||
            cause.name.toLowerCase().includes("cinema") ||
            cause.name.toLowerCase().includes("evento esportivo") ||
            cause.name.toLowerCase().includes("futebol") ||
            cause.name.toLowerCase().includes("música") ||
            cause.name.toLowerCase().includes("patrocínio") ||
            cause.name.toLowerCase().includes("teatro")
        );
      } else {
        data = data.filter(
          (cause) =>
            !cause.name.toLowerCase().includes("carnaval") &&
            !cause.name.toLowerCase().includes("cinema") &&
            !cause.name.toLowerCase().includes("evento esportivo") &&
            !cause.name.toLowerCase().includes("futebol") &&
            !cause.name.toLowerCase().includes("música") &&
            !cause.name.toLowerCase().includes("patrocínio") &&
            !cause.name.toLowerCase().includes("teatro")
        );
      }

      if (this.project.projectTypeId == ProjectTypes.ProjetoIncentivado) {
        this.causeList = data.filter(
          (cause) =>
            !cause.name.toLowerCase().includes("animais") &&
            !cause.name.toLowerCase().includes("meio ambiente")
        );
      } else {
        this.causeList = data;
      }
    },
    listAllTargetAudienceCallback(data) {
      if (this.project.projectTypeId == ProjectTypes.Ticket) {
        data = data.filter(
          (targetAudience) =>
            targetAudience.name.toLowerCase().includes("adultos") ||
            targetAudience.name
              .toLowerCase()
              .includes("pessoa com deficiência (pcd)") ||
            targetAudience.name
              .toLowerCase()
              .includes("adolecentes (acima de 11 anos)") ||
            targetAudience.name
              .toLowerCase()
              .includes("crianças (abaixo de 10 anos)") ||
            targetAudience.name
              .toLowerCase()
              .includes("idosos (acima de 60 anos)") ||
            targetAudience.name.toLowerCase().includes("geral") ||
            targetAudience.name.toLowerCase().includes("somente funcionários")
        );
      } else {
        data = data.filter(
          (targetAudience) =>
            !targetAudience.name
              .toLowerCase()
              .includes("adolecentes (acima de 11 anos)") &&
            !targetAudience.name
              .toLowerCase()
              .includes("crianças (abaixo de 10 anos)") &&
            !targetAudience.name
              .toLowerCase()
              .includes("idosos (acima de 60 anos)") &&
            !targetAudience.name.toLowerCase().includes("geral") &&
            !targetAudience.name.toLowerCase().includes("somente funcionários")
        );
      }

      this.targetAudienceList = data;
    },
    listAllChildrenFundCallback(data) {
      this.childrenFundList = data;
    },
    listAllElderlyFundCallback(data) {
      this.elderlyFundList = data;
    },
    listAllSportingManifestationCallback(data) {
      this.sportingManifestationList = data;
    },
    projectAreasCallback(data) {
      this.projectAreaList = data;
    },
    validateChildrenFundSelect() {
      if (
        this.project.incentiveLawId ==
          IncentiveLaws.EstatutoDeCriancaEAdolescente &&
        this.project.childrenFundId == 0
      ) {
        return false;
      }
      return true;
    },
    validateElderlyFundSelect() {
      if (
        this.project.incentiveLawId == IncentiveLaws.EstatutoDoIdoso &&
        this.project.elderlyFundId == 0
      ) {
        return false;
      }
      return true;
    },
    validateProcessNumber() {
      if (
        this.project.incentiveLawId == IncentiveLaws.LeiFederalDoEsporte &&
        this.project.processNumber == ""
      ) {
        return false;
      }
      return true;
    },
    validatePublicationDateDOU(){
      if (!this.project.publicationDateDOU) {
        return false;
      }
      try{
        var current = parse(this.project.publicationDateDOU);
      } catch (error) {
        return false;
      }
      return true;
    },
    validatePronacNumberInput() {
      if (this.project.pronacNumber.length >= 8) return true;
      return false;
    },
    validateSportingManifestation() {
      if (
        this.project.incentiveLawId == IncentiveLaws.LeiFederalDoEsporte &&
        this.project.sportingManifestationId == 0
      ) {
        return false;
      }
      return true;
    },
    validateODSList() {
      if (this.project.odsList.length > 0) {
        return true;
      }
      return false;
    },
    showFailMessage(invalidElements) {
      if (
        invalidElements.length > 1 ||
        (invalidElements.length == 1 && invalidElements[0] != "odsListInput")
      ) {
        this.$store.commit(
          "SET_SNACKBAR_MESSAGE",
          "Você não preencheu todos os campos"
        );
      } else {
        this.$store.commit(
          "SET_SNACKBAR_MESSAGE",
          "Escolha ao menos um Objetivo de Desenvolvimento Sustentável"
        );
      }
    },
    setValidStep() {
      this.$set(this.project.stepsValidationStatus, "step1", true);
    },
    setInvalidStep() {
      this.$set(this.project.stepsValidationStatus, "step1", false);
    },
    validateStep() {
      let valid = this.isValid(false);
      if (valid) this.setValidStep();
      else this.setInvalidStep();
    },

    loadFavicon() {
      if (this.project.faviconFile == null)
        this.project.faviconFile = new File();
      else if (this.project.faviconFile.id > 0) {
        this.fileService
          .render(this.project.faviconFile.id)
          .then(this.renderShareCallback);
      }
    },
    renderShareCallback(data) {
      const path = data.replace(
        "image/upload",
        "image/upload/w_150,h_150,c_pad,b_auto:predominant/"
      );
      this.$refs.share.style.backgroundImage = "url(" + data + ")";
      this.$refs.favIconPreview1.style.backgroundImage = "url(" + path + ")";
      this.$refs.favIconPreview1.style.backgroundSize = "contain";
      this.$refs.favIconPreview2.style.backgroundImage = "url(" + path + ")";
      this.$refs.favIconPreview2.style.backgroundSize = "contain";
    },

    faviconFileChanged(event) {
      var file = event.target.files[0];
      //tamanho maximo da imagem de 500Kb
      if (file.size > 500 * 1024) {
        this.$store.commit(
          "SET_SNACKBAR_MESSAGE",
          "A imagem deve ter no máximo 500Kb de tamanho"
        );
      } else {
        this.project.faviconFile.name = file.name;
        let reader = new FileReader();
        reader.onload = function () {
          this.$refs.share.style.backgroundImage = "url(" + reader.result + ")";
          this.project.faviconFile.path = reader.result;
        }.bind(this);
        reader.readAsDataURL(file);
      }
    },
  },
};
</script>
